import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RenameLabelPipe } from './rename-label.pipe';
import { ToImageUrlPipe } from './to-image-url.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [RenameLabelPipe, ToImageUrlPipe],
    exports: [RenameLabelPipe, ToImageUrlPipe]
})
export class SharedPipesModule {}
