import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from '../services/global.service';

@Pipe({
    name: 'toImageUrl'
})
export class ToImageUrlPipe implements PipeTransform {
    constructor(private api: GlobalService) {}
    transform(value: string) {
        return value
            ? value.includes('http://') || value.includes('https://')
                ? value
                : this.api.endpointNotAdmin + 'uploads/' + value
            : '';
    }
}
