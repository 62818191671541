import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class GlobalService {
    constructor() {}
    endpoint = environment.apiUrl;
    endpointNotAdmin = environment.apiUrlNotAdmin;
    token = environment.token;
    headers = new HttpHeaders({
        Authorization: this.token
    });
}
