import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'renameLabel'
})
export class RenameLabelPipe implements PipeTransform {
    transform(value: string): string {
        if (value === '' || value === 'bestSale' || value === 'hotPromotion' || value === 'newArrival') {
            value === 'bestSale'
                ? (value = 'Best Sale')
                : value === 'hotPromotion'
                ? (value = 'Hot')
                : value === 'newArrival'
                ? (value = 'New')
                : value === ''
                ? (value = 'All Types')
                : '';
        }

        // console.log(value);
        return value;
    }
}
